import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultipleSelectSearch = _resolveComponent("MultipleSelectSearch")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_MultipleSelectSearch, {
      onFocus: _ctx.fetchCommodity,
      onBlur: _ctx.onBlur,
      options: _ctx.optionCommodity,
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      modalTitle: _ctx.title,
      keyName: _ctx.keyName,
      keyValue: _ctx.keyValue,
      isLoading: _ctx.isLoadingCommodity,
      placeholder: _ctx.placeholder,
      onFilter: _ctx.fetchCommodity,
      ableToDeleteInModal: "",
      isDisabled: _ctx.disabled
    }, null, 8, ["onFocus", "onBlur", "options", "modelValue", "modalTitle", "keyName", "keyValue", "isLoading", "placeholder", "onFilter", "isDisabled"])
  ]))
}