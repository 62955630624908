
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options, prop } from "vue-class-component";
import debounce from "lodash/debounce";
import { CommodityController } from "../../controllers/CommodityController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { Commodity } from "@/domain/entities/Commodity";
import { CargoController } from "../../controllers/CargoController";
import { CargoCommodityData } from "@/domain/entities/Cargo";

class Props {
  modelValue = prop<any>({});
  placeholder = prop<string>({
    default: "Pilih tipe komoditi"
  });
  title = prop<string>({
    default: "Tipe Komoditi"
  });
  status = prop<string>({
    default: ""
  });
  disabled = prop<boolean>({
    default: false
  });
  isCommodityGroup = prop<boolean>({
    default: false
  });
  keyName = prop<string>({
    default: "commodity_name"
  });
  keyValue = prop<string>({
    default: "commodity_code"
  });
}

@Options({
  emits: ["update:modelValue", "change"]
})
export default class MultipleSelectCommodity extends Vue.with(Props) {
  isLoadingCommodity = true;
  listCommodity: Commodity[] = [];
  fetchCommodity = debounce(async (search: string) => {
    this.isLoadingCommodity = true;
    if (this.isCommodityGroup) {
      await CargoController.getCargoCommodityList({
        page: 1,
        limit: 10,
        search: search
      });
      this.listCommodity = CargoController.cargoCommodityList.data.length
        ? CargoController.cargoCommodityList.data.map(
            (item: CargoCommodityData) =>
              new Commodity({
                commodity_id: item.commodityGroupId,
                commodity_code: item.commodityGroupCode,
                commodity_name: item.commodityGroupName
              })
          )
        : [];
    } else {
      const responseCommodity = await CommodityController.getCommodityList(
        new RequestListCommodity({
          search: search,
          commodityStatus: this.status
        })
      );
      this.listCommodity = responseCommodity.data;
    }
    this.isLoadingCommodity = false;
  }, 250);
  get optionCommodity(): Commodity[] {
    return this.listCommodity;
  }
  onBlur() {
    this.isLoadingCommodity = true;
  }
}
